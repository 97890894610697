<script setup>
	import { useRegionsStore } from "~/store/regions/regions";
	import { useCulturesStore } from "@/store/cultures/cultures";
	import { useAgentsStore } from "~/store/agents/agents";
	import { useOrganizersStore } from "~/store/organizers/organizers";
	import { useQualitiesStore } from "~/store/qualities/qualities";

	defineProps({
		modelStoresReady: {
			type: Boolean,
			required: true,
			default: false,
		},
	});

	const emits = defineEmits(["update:modelStoresReady"]);

	const { userRoles } = useNuxtApp();
	const { status } = useAuth();

	const regionStore = useRegionsStore();
	const culturesStore = useCulturesStore();
	const qualitiesStore = useQualitiesStore();
	const agentStore = useAgentsStore();
	const organizersStore = useOrganizersStore();

	const initStores = async () => {
		const promises = [];

		if (!userRoles.isAgent()) {
			promises.push(agentStore.fetchAgents());
			promises.push(organizersStore.fetchOrganizers());
		}

		promises.push(regionStore.fetchSectionTree());
		promises.push(culturesStore.fetchSectionTree());
		promises.push(qualitiesStore.fetchSectionTree());

		await Promise.all(promises);

		emits("update:modelStoresReady", true);
	};

	onBeforeMount(() => {
		if (status.value === "authenticated") initStores();
	});
</script>

<template>
	<div class="store-init"></div>
</template>

<style scoped>
	.store-init {
		position: absolute;
	}
</style>
